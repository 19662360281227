@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.ck-content {
	line-height: 1.6;
	word-break: break-word;
    height: 100% !important;
}

.ck-editor {
    height: 100% !important;
}

.ck-editor__main {
    height: 100% !important;
}

.ck-editor__editable_inline ul, .ck-editor__editable_inline ol {
    padding: 0 20px !important;
}

.ck-editor__editable_inline ul.todo-list {
    padding: 0 30px !important;
}

.ck-editor__editable_inline a {
    color: revert;
    text-decoration: revert;
}

.ck-editor__editable_inline h1,
.ck-editor__editable_inline h2,
.ck-editor__editable_inline h3,
.ck-editor__editable_inline h4,
.ck-editor__editable_inline h5,
.ck-editor__editable_inline h6 {
    font-size: revert;
    font-weight: revert;
}

.ck.ck-editor__editable_inline > *:first-child {
    margin: 15px 0 10px 0;
}