
.tabs {
    display: flex;
    flex-direction: row;
    gap: 0; 
}
.tab-button {
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    width: 80px;
    height: 30px;
    border-radius: 4px 4px 0px 0px;
}

.tab-button.active {
    background-color: #cedee9;
}
