
.publication-content {
    line-height: 1.6;
    word-break: break-word;
    height: 100% !important;
}

.publication_inline {
  overflow: auto;
  padding: 0;
}

.publication_inline > *:first-child {
  margin: 15px 0 10px 0;
}

.publication_inline h1,
.publication_inline h2,
.publication_inline h3,
.publication_inline h4,
.publication_inline h5,
.publication_inline h6 {
    font-size: revert;
    font-weight: revert;
}

.ck-publication_inline ul, .publication_inline ol {
  padding: 0 20px !important;
}

.publication_inline ul.todo-list {
  padding: 0 30px !important;
}

.publication_inline a {
  color: revert;
  text-decoration: revert;
}